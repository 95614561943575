<template>
  <button @click="download" :aria-label="$t('buttons.download')" :title="$t('buttons.download')" id="download-button" class="action">
    <i class="material-icons">file_download</i>
    <span>{{ $t('buttons.download') }}</span>
    <span v-if="selectedCount > 0" class="counter">{{ selectedCount }}</span>
  </button>
</template>

<script>
import {mapState, mapGetters} from 'vuex'
import { files as api } from '../../api'

export default {
  name: 'download-button',
  computed: {
    ...mapState(['fbname', 'fbpath', 'req', 'selected']),
    ...mapGetters(['isListing', 'selectedCount']),
  },
  methods: {
    async download() {
      if (!this.isListing) {
        api.download(null, this.fbname, this.fbpath)
        this.$emit('download-clicked', { fbname: this.fbname, fbpath: this.fbpath })
        return
      }

      if (this.selectedCount === 0) return
      if (this.selectedCount === 1 && !this.req.items[this.selected[0]].isDir) {
        api.download(null, this.fbname, this.req.items[this.selected[0]].url)
        this.$emit('download-clicked', { fbname: this.fbname, fbpath: this.req.items[this.selected[0]].url })
        return
      }

      this.$store.commit('showHover', 'download')
    }
  }
}
</script>
